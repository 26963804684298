import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

export const useReserveStore = defineStore('reserve', {
  state: () => ({
		filters: { "search": "" }
	}),

  getters: {
		tableFilters: state => state.filters,
	},

  actions: {
    index: (filters = {}) => {
      const page = filters.page ? filters.page + 1 : 1

      return new Promise((resolve) => {
				axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/reserves`, {
					params: { ...filters, page }
				}).then(({data}) => {
					resolve(data)
				}).catch(() => resolve(null))
			})
    },
    show: (id) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/reserves/${id}`),
		update: (id, reserve) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/reserves/${id}`, reserve),
  }
})
