<script setup>
    import { onMounted, ref, nextTick } from 'vue'

    import { storeToRefs } from 'pinia'
    import { useReserveStore } from '@/store/reserve'

    import MZBreadcrumb from '@/components-chillpainai/MZBreadcrumb.vue'

    const reserveStore = useReserveStore()
    const { tableFilters } = storeToRefs(reserveStore)

    const datatable = ref()
    const loading = ref(false)
    const totalRecords = ref(0)
    const dataList = ref()

    const search = (filters) => {
        if (filters) {
            datatable.value.resetPage()
        }

        loading.value = true

        return new Promise(resolve => {
            dataList.value = [];
            totalRecords.value = 0;

            reserveStore.index(reserveStore.filters).then(data => {
                nextTick(() => {
                    dataList.value = data.reserves.data
                    totalRecords.value = data.reserves.total
                })

                loading.value = false

                resolve()
            })
        })
    }

    const onFilter = (event) => {
        reserveStore.filters = { ...reserveStore.filters, ...event }
        search()
    }

    const reset = () => {
        datatable.value.resetPage()
        reserveStore.filters = {}
        search()
    }

    onMounted(() => {
        reserveStore.filters = { search: '', sortField: 'id', sortOrder: 1 }
        search()
    })

</script>

<template>
    <MZBreadcrumb :items="[{ label: 'Reserve' }, { label: 'Index' }]" />

    <br/>

    <div class="flex flex-wrap justify-content-center card-container">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <div class="p-fluid">
                            <h5 class="card-title">รายการจอง</h5>
                            <h6 class="card-subtitle mb-2 text-muted"></h6>
                            <p class="card-text"></p>

                            <DataTable
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                dataKey="id"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                responsiveLayout="scroll"
                                :first="tableFilters.first || 0"
                                :lazy="true"
                                :paginator="true"
                                ref="datatable"
                                :resizableColumns="true"
                                :rowHover="true"
                                :rows="tableFilters.rows || 10"
                                :rowsPerPageOptions="[10,25,50]"
                                :totalRecords="totalRecords"
                                sortField="id"
                                :sortOrder="1"
                                :value="dataList"

                                @page="onFilter($event)"
                                @sort="onFilter($event)"
                            >
                                <template #header>
                                    <div class="flex justify-content-between">
                                        Reserves
                                        <div class="flex">
                                            <span class="p-input-icon-right ml-3">
                                                <i
                                                    class="pi pi-times"
                                                    @click="tableFilters.search = ''; search();"
                                                />
                                                <InputText
                                                    v-model="tableFilters.search"
                                                    placeholder="Search"
                                                    type="text"
                                                />
                                            </span>
                                            <div>
                                                <Button 
                                                    icon="pi pi-search" 
                                                    class="p-button-rounded ml-2" 
                                                    @click="search({search: tableFilters.search})" 
                                                />
                                            </div>
                                            <div>
                                                <Button 
                                                    icon="pi pi-replay" 
                                                    class="p-button-rounded p-button-danger ml-2" 
                                                    @click="reset" 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <template #empty>
                                    <template v-if="loading">
                                        <ProgressBar
                                            mode="indeterminate"
                                            style="height: .2em"
                                        />
                                        <Skeleton
                                            v-for="i in (tableFilters.rows || 10)"
                                            :key="`skel-${i}`"
                                            class="mt-2"
                                            height="34px"
                                        />
                                    </template>
                                    <div
                                        v-else
                                        class="text-center font-bold my-3"
                                    >
                                        No reserves found.
                                    </div>
                                </template>

                                <template #loading>
                                    Loading reserves data. Please wait.
                                </template>

                                <Column field="id" header="ID" :sortable="true">
                                    <template #body="slotProps">
                                        <span>#{{ slotProps.data.id }}</span>
                                        <br/>
                                        <Badge v-if="slotProps.data.type === 'new'" :value="slotProps.data.type" />
                                        <Badge v-else-if="slotProps.data.type === 'confirmed'" :value="slotProps.data.type" severity="success" />
                                        <Badge v-else-if="slotProps.data.type === 'canceled'" :value="slotProps.data.type" severity="danger" />
                                    </template>
                                </Column>

                                <Column field="uid" header="UID" :sortable="true" />

                                <Column header="Customer">
                                    <template #body="slotProps">
                                        <span>{{ slotProps.data.customer_name }}</span>
                                        <br/>
                                        <span>{{ slotProps.data.customer_email }}</span>
                                        <br/>
                                        <span>{{ slotProps.data.customer_phone }}</span>
                                    </template>
                                </Column>

                                <Column header="Tour">
                                    <template #body="slotProps">
                                        <span>{{ slotProps.data.tour.title }}</span>
                                        <br/>
                                        <span>{{ slotProps.data.tour.location }}</span>
                                    </template>
                                </Column>

                                <Column field="created_at" header="Created" :sortable="true">
                                    <template #body="slotProps">
                                        {{ new Date(slotProps.data.created_at).toLocaleString() }}
                                    </template>
                                </Column>

                                <Column header="Options">
                                    <template #body="{ data }">
                                        <router-link :to="{name: 'reserves.edit', params: {id: data.id}}">
                                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2 my-1" />
                                        </router-link>

                                        <span :idx="data.id" />
                                    </template>
                                </Column>

                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
